<template>
    <div class="background">
      <div  style="display: flex; align-items:center; height:100%; width:100%; justify-content:center;">
            <h1 style="font-size: 3em; color: #fff;" >Meet Our Team</h1>
      </div>
   </div>
   <section class="team-section">
        <div class="team-grid">
            <div class="team-member">
                <img src="../assets/Siddharth Dave, Co-President, Schulich Finance Association.webp" alt="Siddharth Dave">
                <h3>Siddharth Dave</h3>
                <p>Co-President</p>
            </div>
            <div class="team-member">
                <img src="../assets/Fedor Kornachev, Co-President, Schulich Finance Association.webp" alt="Fedor Kornachev">
                <h3>Fedor Kornachev</h3>
                <p>Co-President</p>
            </div>
            <div class="team-member">
                <img src="../assets/Sara Amin Co-Director Marketing Schulich Finance Association.webp" alt="Sara Amin">
                <h3>Sara Amin</h3>
                <p>Co-Director - Marketing</p>
            </div>
            <div class="team-member">
                <img src="../assets/Arjun Dewan, Co-Director of Marketing, S.webp" alt="Arjun Devan">
                <h3>Arjun Devan</h3>
                <p>Co-Director - Marketing</p>
            </div>
            <div class="team-member">
                <img src="../assets/Sarah Wade Headshot, Co-Director of Marketing, Schulich Finance Association_JPG.webp" alt="Sarah Wade">
                <h3>Sarah Wade</h3>
                <p>Co-Director - Marketing</p>
            </div>
            <div class="team-member">
                <img src="../assets/Anuj, Co-Director of Corporate Relations.webp" alt="Anuj Mehta">
                <h3>Anuj Mehta</h3>
                <p>Co-Director - Corporate Relations</p>
            </div>
            <div class="team-member">
                <img src="../assets/Kyle Farren, Director Corporate Relation.webp" alt="Kyle Farren">
                <h3>Kyle Farren</h3>
                <p>Co-Director - Corporate Relations</p>
            </div>
            <div class="team-member">
                <img src="../assets/Parin Parikh, Co-Director of Corporate Relations, SFA Club.webp" alt="Parin Parikh">
                <h3>Parin Parikh</h3>
                <p>Co-Director - Corporate Relations</p>
            </div>
            <div class="team-member">
                <img src="../assets/Mohit Shah_edited.webp" alt="Mohit Shah">
                <h3>Mohit Shah</h3>
                <p>Co-Director - Corporate Relations</p>
            </div>
            <div class="team-member">
                <img src="../assets/David Jesin, Co-Director of Events, Schulich Finance Association.webp" alt="David Jesin">
                <h3>David Jesin</h3>
                <p>Co-Director - Events</p>
            </div>
            <div class="team-member">
                <img src="../assets/Gloria Arthur, Co-Director of Events, Schulich Finance Association.webp" alt="Gloria Arthur">
                <h3>Gloria Arthur</h3>
                <p>Co-Director - Events</p>
            </div>
            <div class="team-member">
                <img src="../assets/Kudrat Kahlon, Co-Director of Events, Schulich Finance Association.webp" alt="Kudrat Kahlon">
                <h3>Kudrat Kahlon</h3>
                <p>Co-Director - Events</p>
            </div>
            <div class="team-member">
                <img src="../assets/Harbind Thapar, Co-Director of SIF, Schu.webp" alt="Harbind Thapar">
                <h3>Harbind Thapar</h3>
                <p>Co-Director - SIF</p>
            </div>
            <div class="team-member">
                <img src="../assets/Ayesha Malik, Co-Director SIF, Schulich Finance Association.webp" alt="Ayesha Malik">
                <h3>Ayesha Malik</h3>
                <p>Co-Director - SIF</p>
            </div>
            <div class="team-member">
                <img src="../assets/Jhanvi Shah, Co-Director of SIF, Schulich Finance Association.webp" alt="Jhanvi Shah">
                <h3>Jhanvi Shah</h3>
                <p>Co-Director - SIF</p>
            </div>
            <div class="team-member">
                <img src="../assets/Omer Rahim, Co-Director of SIF, Schulich Finance Association.webp" alt="Omer Rahim">
                <h3>Omer Rahim</h3>
                <p>Co-Director - SIF</p>
            </div>
            <div class="team-member">
                <img src="../assets/Vincent Chung, Co-Director of SIF, Schulich Finance Association.webp" alt="Vincent Chung">
                <h3>Vincent Chung</h3>
                <p>Co-Director - SIF</p>
            </div>
            <div class="team-member">
                <img src="../assets/Yoshna Gude, Director of Finance, Schulich Finance Association .webp" alt="Yoshna Gude">
                <h3>Yoshna Gude</h3>
                <p>Director - Finance</p>
            </div>
            <div class="team-member">
                <img src="../assets/yash9815@schulich.yorku.ca-0.jpg" alt="Yash Datta">
                <h3>Yash Datta</h3>
                <p>Director - Technology</p>
            </div>
        </div>
    </section>

</template>
<script>
export default {
  name: "team",
  components: {},
  mounted(){ 
    console.log("test")
   
      
      
  }
};
</script>

<style lang="scss" scoped>
.background {
    background: linear-gradient(132deg, #FC415A, #591BC5, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    height: 50vh;
    width: 100%;
    position: static;
    overflow: hidden;
    padding:0;
    margin:0px;
  }

  
  /* Animate Background*/
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.team-section {
    padding: 20px;
    text-align: center;
    background-color: white;
}

.team-section h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.team-member {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.team-member img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
}

.team-member h3 {
    font-size: 18px;
    margin-bottom: 5px;
    color: #2a69ac;
}

.team-member p {
    font-size: 14px;
    color: #555;
    margin: 5px 0;
}

.team-member:hover {
    transform: translateY(-10px);
}

.team-member p:first-of-type {
    font-weight: bold;
    color: #333;
}


</style>