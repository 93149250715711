<template >
  <div id="app">
  <div class="container">
    <nav class="navbar">
        <div class="brand-title">
          <router-link id="logo" to="/" style="display: flex; align-items: center;">
            <img src="@/assets/SFA_LOGO.png" class="logo" style="max-height: 50px;" alt="Logo">
          </router-link>
        </div>
        <a href="javascript:void(0);" class="toggle-button" @click="toggleNavbar">
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </a>
        <div class="navbar-links" :class="{ active: isNavbarOpen }" id="navbar-links">
          <ul>
            <li><router-link to="/" @click="closeNavbar">Home</router-link></li>
            <li><router-link to="/events" @click="closeNavbar">Events</router-link></li>
            <li><router-link to="/team" @click="closeNavbar">Leadership Team</router-link></li>
            <li><router-link to="/newsletters" @click="closeNavbar">Newsletters</router-link></li>
            <li><router-link to="/contact-us" @click="closeNavbar">Contact Us</router-link></li>
          </ul>
        </div>
      </nav>

    
    <!-- <nav class="navbar">
        <div class="brand-title"><router-link id="logo" to="/" style="display: flex; align-items: center;"><img src="@/assets/SFA_LOGO.png" class="logo" style="max-height: 50px;" alt="Logo"></router-link></div>
        <a href="javascript:void(0);" class="toggle-button" @click="this.toggleNavbar()">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
        </a>
        <div class="navbar-links" id="navbar-links">
            <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/events">Events</router-link></li>
                <li><router-link to="/team">Leadership Team</router-link></li>
                <li><router-link to="/newsletters">Newsletters</router-link></li>
                <li><router-link to="/resources">Resources</router-link></li>
                <li><router-link to="/contact-us">Contact Us</router-link></li>
            </ul>
        </div>
    </nav> -->
    <!-- <div id="nav">
      <div class="first-nav">
        <router-link id="logo" to="/" style="display: flex; align-items: center;"><img src="@/assets/SFA_LOGO.png" style="max-height: 50px;" alt="Logo"></router-link>
      </div>
      <div class="second-nav">
        <router-link to="/">Home</router-link>
        <router-link to="/events">Events</router-link>
        <router-link to="/team">Leadership Team</router-link>
        <router-link to="/newsletters">Newsletters</router-link>
        <router-link to="/resources">Resources</router-link>
        <router-link to="/contact-us">Contact Us</router-link>
      </div>  
    </div> -->
    <router-view v-slot="{ Component, route }">
      <transition name="fade" :key="route" mode="out-in">

        <component :is="Component" />
      </transition>
    </router-view>
    <footer class="footer">
        <nav class="footer-nav">
          <router-link to="/">Home</router-link>
          <router-link to="/events">Events</router-link>
          <router-link to="/team">Leadership Team</router-link>
          <router-link to="/newsletters">Newsletters</router-link>
          <!-- <router-link to="/resources">Resources</router-link> -->
          <router-link to="/contact-us">Contact Us</router-link>
        </nav>
        <div class="footer-socials">
            <a href="https://www.instagram.com/sfa_schulich/" class="social-link"> 
                <img src="@/assets/instagram.svg" alt="LinkedIn">
            </a>
            <a href="https://www.linkedin.com/company/schulich-finance-association/" class="social-link">
                <img src="@/assets/linkedln.svg" alt="Instagram">
            </a>
        </div>
        <div class="footer-logo">
            <img src="@/assets/SFA_LOGO.png" class="logo" style="max-height: 50px;" alt="Logo">
        </div>
        <div class="footer-bottom">
            <p>© 2024 Schulich Finance Association</p>
            <a href="https://execed.schulich.yorku.ca/privacy-policy/" class="privacy-policy">Privacy Policy</a>
        </div>
    </footer>
  </div>
</div>
</template>
<script>

export default {
  data(){
    return{
      isNavbarOpen: false

    }
  },mounted(){
   
  },
  methods: {
  //   toggleNavbar() {
  //   var navbarLinks = document.getElementById("navbar-links");
  //   if (navbarLinks.style.display === "block") {
  //       navbarLinks.style.display = "none";
  //   } else {
  //       navbarLinks.style.display = "block";
  //   }
  // }
  toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },
    closeNavbar() {
      this.isNavbarOpen = false;
    }

      
    },
    computed:{
     
    }


  
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
html,
body {
  height: 100%;
  font-family: "Poppins", sans-serif;
  background-color: #f6f7f9;
  overflow-x: hidden !important;
}
.container {
  width: 100%;
  margin: 0 auto;
}

.background {
  position: sticky;
  height: 100vh;
  z-index: -100;
  background-color: blue;
}

#nav {
  position: relative;
  z-index: 1;
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  a {
    color: #494949;
    text-decoration: none;
    margin-left: 20px;
    font-size: 16px;
    &:hover {
      text-decoration: underline;
    }
  }

  #logo {
    margin-left: 0;
    margin-right: 20px;
    color: black;
    font-size: 36px;
    font-family: "Shrikhand", cursive;
    text-decoration: none;
  }

  .second-nav {
    a {
      padding: 5px 15px;
      text-decoration: none;
    }
  }
  .first-nav{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.drop-shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.02);
  }
}

button{
  width: 100px;
    height: 40px;
    background-color: white;
    border: 1px solid skyblue;
    cursor: pointer;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-image: linear-gradient(45deg, #0056b3, #000000);
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);
}

.brand-title {
    color: white;
    font-size: 1.5em;
}

.navbar-links ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}

.navbar-links li {
    padding: 0 10px;
}

.navbar-links a {
    color: white;
    text-decoration: none;
    font-size: 1.2em;
}

.toggle-button {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.toggle-button .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
}

// @media (max-width: 768px) {
//     .toggle-button {
//         display: flex;
//     }
    
//     .navbar-links {
//         display: none;
//         width: 100%;
//     }

//     .navbar-links ul {
//         flex-direction: column;
//         width: 100%;
//         text-align: center;
//     }

//     .navbar-links ul li {
//         margin: 10px 0;
//     }
// }
@media (max-width: 992px) {
  .toggle-button {
    display: flex;
  }

  .navbar-links {
    display: none;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #0056b3;
    z-index: 10;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  }

  .navbar-links.active {
    display: block;
  }

  .navbar-links ul {
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .navbar-links ul li {
    margin: 10px 0;
  }
}
.footer {
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
    border-top: 1px solid #e0e0e0;
    background-image: linear-gradient(45deg, #0056b3, #000000);
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);
    width: 100%;

}

.footer-nav {
    margin-bottom: 20px;
}

.logo{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
  }
.footer-nav a {
    margin: 0 15px;
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    transition: color 0.3s ease;
}

.footer-nav a:hover {
    color: #007bff;
}

.footer-socials {
    margin-bottom: 20px;
}

.footer-socials .social-link {
    margin: 0 10px;
    display: inline-block;
}

.footer-socials .social-link img {
    width: 24px;
    height: 24px;
    background-color: #fff;
}

.footer-logo img {
    width: 50px;
    height: auto;
    margin-bottom: 20px;
}

.footer-bottom {
    font-size: 12px;
    color: #fff;
}

.footer-bottom a {
    color: #007bff;
    text-decoration: none;
    margin-left: 10px;
    font-size: 12px;
}

.footer-bottom a:hover {
    text-decoration: underline;
}
</style>
