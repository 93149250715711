<template>
    <div class="background">
      <div  style="display: flex; align-items:center; height:100%; width:100%; justify-content:center;">
            <h1 style="font-size: 3em; color: #fff;" >Newsletters</h1>
      </div>
   </div>
   <div class="newsletter-container">

        <div class="newsletter-item">
            <img src="../assets/newsletter7.jpeg" alt="Newsletter Banner 1">
            <div class="newsletter-content">
                <h2>Is the IRA Here to Stay, or On Borrowed Time?</h2>
                <a href="https://www.linkedin.com/pulse/ira-here-stay-borrowed-time-schulich-finance-association-r1wac/?trackingId=Rd%2FFmYV4SDKI4RwylT9U0A%3D%3D" class="btn">Read More</a>
            </div>
        </div>

        <div class="newsletter-item">
            <img src="../assets/newsletter6.jpeg" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>Market Update</h2>
                <a href="https://www.linkedin.com/pulse/market-update-schulich-finance-association-xgdlc/?trackingId=K2FJ5q9%2BTQ60rnWJVPemTw%3D%3D" class="btn">Read More</a>
            </div>
        </div>
        <div class="newsletter-item">
            <img src="../assets/newsletter5.png" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>Navigating Steady Rates and Market Ripples</h2>
                <a href="https://www.linkedin.com/pulse/navigating-steady-rates-market-ripples-schulich-finance-association-sswec/?trackingId=rtBQgDCfQNW4Q%2FTOUlciIA%3D%3D" class="btn">Read More</a>
            </div>
        </div>
        <div class="newsletter-item">
            <img src="../assets/newsletter4.png" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>BoC: Two Cuts Done, More in 2024?</h2>
                <a href="https://www.linkedin.com/pulse/boc-two-cuts-done-more-in2024-schulich-finance-association-6dv6c/?trackingId=DYopl4SfS%2B2us0vtReztMQ%3D%3D" class="btn">Read More</a>
            </div>
        </div>
        <div class="newsletter-item">
            <img src="../assets/newsletter3.png" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>Canada's Interest Rate Conundrum: Balancing Inflation and Economic Growth</h2>
                <a href="https://www.linkedin.com/pulse/canadas-interest-rate-conundrum-balancing-v48kc/?trackingId=5ARQEWCYSWO%2FZrI4JWZ9Mw%3D%3D" class="btn">Read More</a>
            </div>
        </div>
        <div class="newsletter-item">
            <img src="../assets/newsletter2.png" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>Politics: Hotter over the Weekend!</h2>
                <a href="https://www.linkedin.com/pulse/politics-hotter-over-weekend-schulich-finance-association-wentc/?trackingId=MiCI1rJ1QAe87%2BndmpbG2w%3D%3D" class="btn">Read More</a>
            </div>
        </div>
        <div class="newsletter-item">
            <img src="../assets/newsletter1.png" alt="Newsletter Banner 2">
            <div class="newsletter-content">
                <h2>Introducing Capital Currents by SFA</h2>
                <a href="https://www.linkedin.com/pulse/introducing-capital-currents-sfa-schulich-finance-association-n2smc/?trackingId=FQUulBfBSEq6RhmyU%2BAY1Q%3D%3D" class="btn">Read More</a>
            </div>
        </div>

        <!-- Add more newsletter items as needed -->

    </div>

</template>
<script>
</script>
<style lang="scss" scoped>
.background {
    background: linear-gradient(132deg, #FC415A, #591BC5, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    height: 50vh;
    width: 100%;
    position: static;
    overflow: hidden;
    padding:0;
    margin:0px;
  }



  
  /* Animate Background*/
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.newsletter-container {
    width: 100%;
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: linear-gradient(180deg, transparent, #0056b3, transparent);
}

.newsletter-container h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  
}

.newsletter-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    width: 70%;
    flex-direction: column;
}

.newsletter-item:hover {
    transform: translateY(-5px);
}

.newsletter-item img {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
}

.newsletter-content {
    padding: 20px;
    flex-grow: 1;
}

.newsletter-content h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.newsletter-content p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.6;
}

.btn {
    padding: 10px 20px;
    background-color: #0056b3;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    font-size: 16px;
    display: inline-block;
}

.btn:hover {
    background-color: #0056b3;
}
</style>