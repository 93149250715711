<template>
    <div class="background">
      <div  style="display: flex; align-items:center; height:100%; width:100%; justify-content:center;">
            <h1 style="font-size: 3em; color: #fff;" >Contact Us</h1>
      </div>
   </div>
   <div class="contact-us">
    <iframe
  width="100%"
  height="300px"
  style="border:0"
  loading="lazy"
  allowfullscreen
  referrerpolicy="no-referrer-when-downgrade"
  src="https://maps.google.com/maps?f=q&source=s_q&hl=en&q=111 Ian MacDonald Blvd,Toronto ,ON,M3J 1P3&ie=UTF8&output=embed&iwloc=near">
</iframe>
<div class="container">
    <div class="left-section">
        <h2>Schulich Finance Association</h2>
        <div class="contact-info">
            <h3>Contact Us</h3>
            <p><a href="mailto:sfa@schulich.yorku.ca" style="color: white; text-decoration: none;">sfa@schulich.yorku.ca</a></p>
            <div class="social-icons">
                <a href="https://www.instagram.com/sfa_schulich/"><img src="../assets/instagram.svg"  alt="instagram"></a>
                <a href="https://www.linkedin.com/company/schulich-finance-association/"><img src="../assets/linkedln.svg" alt="linkedln"></a>
            </div>
        </div>
        <div class="location-info">
            <h3>Our Location</h3>
            <p>111 Ian<br>
               MacDonald Blvd<br>
               Toronto, ON,<br>
               M3J 1P3</p>
        </div>
    </div>
    <div class="right-section">
        <p>Thank you for your interest in the Schulich Finance Association (SFA). We're here to help with any questions you may have.</p>
        <div class="contact-container">
        <form class="contact-form" action="#" method="POST">
            <div class="form-group">
                <label for="name">Name</label>
                <input type="text" id="name" name="name" v-model="name" required>
                <p v-if="error.name">{{ error.name }}</p>
            </div>
            <div class="form-group">
                <label for="email">Email Address</label>
                <input type="email" id="email" name="email" v-model="email" required>
                <p v-if="error.email">{{ error.email }}</p>
            </div>
            <div class="form-group">
                <label for="message">Message</label>
                <textarea id="message" name="message" rows="5" v-model="message" required></textarea>
                <p v-if="error.message">{{ error.message }}</p>
            </div>
            <button type="submit" @click="submitHandler">Submit</button>
        </form>
        <p v-if="alertMessage" class="alert">{{ alertMessage }}</p>
    </div>
    </div>
</div>

   </div>
   


</template>
<script>
// Initialize and add the map
import axios from "axios";
import { instance } from "../api";
export default {
  name: "Home",
  components: {},
  data(){
    return{
      name:"",
      email:"",
      message:"",
      error:{
        name:"",
        email:"",
        message:""
      },
      alertMessage: ""
    };
  },
  mounted(){ 
   
      
      
  },
  methods:{
    async submitHandler(){
      const formValidation = this.validateForm();
      if (!formValidation) {
        return;
      }
      this.sendEmail();
      this.clearForm();

    },
    validateForm() {
      // validate blanks
      let formStatus = true;
      if (this.name.length === 0) {
        this.error.name = "Name cannot be empty";
        formStatus = false;
      }
      if (this.email.length === 0) {
        this.error.email = "Email cannot be empty";
        formStatus = false;
      }
      if (this.message === 0) {
        this.error.message = "Message cannot be empty";
        formStatus = false;
      }
      
      return formStatus;
    },
    clearForm() {
      this.name = "";
      this.email = "";
      this.message = "";
    },
    async sendEmail() {
      try {
        const response = await instance.post('/send-email', {
          recipients: this.email,
          subject: "Contact Us",
          text: this.message
        });
        this.alertMessage = response.data.message;
      } catch (error) {
        this.alertMessage = 'Failed to send email';
      }
    },

  }
};

</script>
<style lang="scss" scoped>
.background {
    background: linear-gradient(132deg, #FC415A, #591BC5, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    height: 50vh;
    width: 100%;
    position: static;
    overflow: hidden;
    padding:0;
    margin:0px;
  }

  
  /* Animate Background*/
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.contact-us{
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.contact-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin-top: 10px;
}

.contact-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    font-size: 14px;
    color: #555;
}

.form-group input, 
.form-group textarea {
    width: 90%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease-in-out;
}

button:hover {
    background-color: #0056b3;
}

#map {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    border-radius: 8px;
}
.container {
            display: flex;
            justify-content: center;
            padding: 50px;
            background-color: #000000;
            color: #ffffff;
            margin: 0 auto;
            flex-wrap: wrap;
            align-items: center;
        }

        .left-section {
            text-align: left;
            max-width: 300px;
        }

        .left-section h2 {
            font-family: 'Georgia', serif;
            font-size: 24px;
            margin-bottom: 20px;
        }

        .left-section p {
            margin: 0;
            line-height: 1.5;
        }

        .left-section .social-icons img {
            width: 24px;
            margin: 10px;
            background-color: #fff;

        }

        .right-section {
            text-align: left;
            max-width: 600px;
        }

        .right-section p {
            font-size: 18px;
            line-height: 1.6;
        }

        .contact-info {
            margin-bottom: 30px;
        }

        .contact-info h3 {
            font-size: 18px;
            margin-bottom: 10px;
        }

        .location-info h3 {
            font-size: 18px;
            margin-bottom: 10px;
        }

        .alert {
  margin-top: 15px;
  padding: 10px;
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  text-align: center;
}

</style>