<template>
    <div class="background">
      <div  style="display: flex; align-items:center; height:100%; width:100%; justify-content:center;">
            <h1 style="font-size: 3em; color: #fff;" >Events</h1>
      </div>
    </div>
      <section class="event-section">
        <div class="event-card">
            <img src="../assets/Event1.jpeg" alt="Career Panels">
            <h3>Career Panels</h3>
            <p>Join our M.Fin + MBA alum to learn more about your career aspirations including:</p>
            <ul>
                <li>Risk management</li>
                <li>Wealth management</li>
                <li>Credit and equity analysis</li>
                <li>Relationship banking</li>
            </ul>
        </div>

        <div class="event-card">
            <img src="../assets/Event2.jpeg" alt="Networking Socials">
            <h3>Networking Socials</h3>
            <p>Join us for professional networking every month, such as Day on Bay, our joint event with the CDC where you meet teams from various bank representatives and Schulich alum.</p>
        </div>

        <div class="event-card">
            <img src="../assets/Event3.jpeg" alt="Case Competitions">
            <h3>Case Competitions</h3>
            <p>Stay tuned for Stock Pitch and StockTrak competitions in the fall and winter terms!</p>
        </div>
    </section>


</template>
<script>
</script>
<style lang="scss" scoped>
.background {
    background: linear-gradient(132deg, #FC415A, #591BC5, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    height: 50vh;
    width: 100%;
    position: static;
    overflow: hidden;
    padding:0;
    margin:0px;
  }

  
  /* Animate Background*/
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.event-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

.event-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    text-align: center;
    padding: 20px;
}

.event-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 1px solid #ddd;
}

.event-card h3 {
    font-size: 20px;
    margin: 15px 0;
    color: #333;
}

.event-card p {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
    text-align: left;
}

.event-card ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
}

.event-card ul li {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}

.event-card:hover {
    transform: translateY(-5px);
}
</style>